<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            注册与登录
        </div>
        <div class="vip-content-inner">
            <div class="rich-text">
                <p>欢迎注册一方科技</p>
                <p>请点击“注册”链接，按照流程操作即可</p>
                <br>
                <div class="inline-block">
                    注册步骤如下：
                </div>
                <br>
                <br>
                <p>1、进入网站首页：http:/www.yifanglab.com/，点击页面右上方“注册”链接；</p>
                <br>
                <img src="@/assets/img/rp1.jpg" alt="">
                <br>
                <p>2、按照页面提示，填写登录信息，勾选“我已阅读并同意”，完成后点击下一步。</p>
                <br>
                <img src="@/assets/img/rp2.jpg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "registerAndLogin"
}
</script>

<style scoped>

</style>
